import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue'
import  store from './common/store/index.js'

import 'ant-design-vue/dist/antd.less'


import  './assets/css/font.css'
import './assets/css/common.css'

Vue.use(Antd)

Vue.config.productionTip = false

import  router from './common/router/router';
import  * as http  from './common/request/request.js';
Vue.prototype.$http = http;
import {common} from './common/mixin/common.js';
Vue.mixin(common);

import VCharts from 'v-charts';
Vue.use(VCharts);

import secondmenu from './components/menu/secondmenu.vue';
import menutitle from './components/menu/menutitle.vue';
import seletepicture from './components/upload/seletepicture.vue';
import seletevideo from './components/upload/seletevideo.vue';
Vue.component('second-menu', secondmenu);
Vue.component('menu-title', menutitle);
Vue.component('selete-picture', seletepicture);
Vue.component('selete-video', seletevideo);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')