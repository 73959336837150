import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter);

const routerList = [{
		path: '',
		component: () => import('../../pages/Login.vue'),
		name: 'login',
		meta: {
			title: '登录'
		},
	},
	{
		path: '/404',
		component: () => import('../../pages/404.vue'),
		name: 'nodata404',
		meta: {
			title: '抱歉您找的页面失踪了'
		},
	}
];

const storeRouter = {
	path: "/layout",
	component: () => import('../../pages/Layout.vue'),
	meta: {
		title: '总后台'
	},
	name: 'layout',
	children: [{
		path: '/refresh',
		component: () => import('../../pages/Refresh.vue'),
		name: 'refresh',
		meta: {
			title: '刷新页面'
		},
	}]
}

const files = require.context('./routers', false, /\.js$/)

files.keys().forEach(key => {
	let menuLocal = files(key).default;
	for (var a in menuLocal) {
		storeRouter.children.push(menuLocal[a]);
	}
})
routerList.push(storeRouter);


const routerObj = new VueRouter({
	routes: routerList
});


routerObj.beforeEach((to, from, next) => {
	if (to.name == null) {
		next({
			path: '/404'
		});
	} else {
		document.title = to.meta.title + ' - 应用管理后台'
		next();
	}
})

export default routerObj;