<template>
  <div>
    <a-modal
      :title="getTitle"
      :width="800"
      :visible="visible"
      @cancel="handleCancel"
      :bodyStyle="{ padding: '0px', height: '600px' }"
    >
      <a-layout style="background: #fff">
        <a-layout-sider
          style="
            background: none;
            border-right: 1px solid #efefef;
            height: 600px;
          "
          width="140px"
        >
          <a-menu
            style="width: 100%; height: 90%; padding: 16px; overflow: auto"
            mode="inline"
            :inlineIndent="10"
          >
            <template v-for="(item, index) in menus">
              <a-menu-item
                :key="index"
                :class="{ on: item.id == cate_id }"
                @click="selectCate(item.id)"
              >
                <span class="ft12">{{ item.name }}</span>
              </a-menu-item>
            </template>
          </a-menu>
          <div class="flex center pd10">
            <a-popconfirm
              placement="top"
              ok-text="确定"
              cancel-text="取消"
              @confirm="popconfirm"
            >
              <template slot="title">
                <p>分类名称</p>
                <p>
                  <a-input
                    placeholder="分类名称"
                    v-model="form.cate_name"
                    :suffix="`${form.cate_name.length}/5`"
                    :maxLength="5"
                    style="width: 130px"
                  />
                </p>
              </template>
              <a-button>新建分类</a-button>
            </a-popconfirm>
          </div>
        </a-layout-sider>
        <a-layout-content style="padding: 16px">
          <div class="flex alcenter space">
            <a-input-search
              placeholder="搜索图片名称"
              style="width: 200px"
              @search="getdatas"
              v-model="form.pic_name"
            />
            <div class="flex alcenter">
              <div class="ft12 cl-info mr10">建议大小不要超过2M</div>
              <a-upload
                list-type="picture"
                :multiple="!0"
                :showUploadList="false"
                :customRequest="uploadImg"
                :beforeUpload="beforeUpload"
              >
                <a-button type="primary"> 上传图片 </a-button>
              </a-upload>
            </div>
          </div>
          <div class="mt30" style="height:450px">
            <a-row justify="center" align="middle">
              <a-col
                class="img-box"
                :span="5"
                v-for="(item, index) in datas"
                :key="index"
                @mouseenter="enters(index)"
                @mouseleave="leaver(index)"
              >
                <div
                  class="img"
                  :style="{ backgroundImage: `url(${item.url})` }"
                  @click="selectImg(index)"
                ></div>
                <a-icon
                  class="delete_icon ft16 bg-w"
                  type="close-circle"
                  theme="filled"
                  @click.stop="deleteImg(index)"
                  v-show="item.is_hover"
                />
                <div
                  class="select-layer"
                  @click.stop="clearImg(index)"
                  v-show="item.is_select"
                >
                  <a-icon class="ft20" type="check" />
                  <div class="number">{{ item.number }}</div>
                </div>
                <div class="img-layer" v-show="item.is_hover">
                  {{ item.text }}
                </div>
                <div class="text-over text-over1">{{ item.name }}</div>
              </a-col>
            </a-row>
          </div>
          <div class="pt20 flex alcenter space">
            <div class="flex alcenter">
              <template v-if="selectLen > 0">
                <div class="ft12 cl-info mr10">已选{{ selectLen }}张</div>
                <a-button @click="clearAll">取消选择</a-button>
              </template>
            </div>
            <a-pagination
              show-quick-jumper
              :defaultPageSize="pagination.pageSize"
              v-model="pagination.current"
              :total="pagination.total"
              @change="onChange"
            />
          </div>
        </a-layout-content>
      </a-layout>
      <template slot="footer">
        <a-button key="back" @click="handleCancel"> 取消 </a-button>
        <a-button
          key="submit"
          type="primary"
          @click="onSubmit"
          :loading="confirmLoading"
        >
          保存
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    num: {
      type: Number,
      default: 9,
    },
    value: {
      type: [String,Array],
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      confirmLoading: false,
      menus: [
        { id: 0, name: "全部" },
        { id: 1, name: "分类一" },
        { id: 2, name: "分类二" },
        { id: 3, name: "分类三" },
      ],
      cate_id: 0,
      form: {
        cate_name: "",
        pic_name: "",
      },
      pagination: {
        current: 1,
        pageSize: 15, //每页中显示10条数据
        total: 10,
      },
      selectLen: 0,
      datas:[]
    };
  },
  computed: {
    getTitle() {
      return "图片选择器";
    },
  },
  created(){
    console.log(this.value)
    this.getdatas()
  },
  methods: {
    getdatas() {
      this.datas=[
        {
          url: "https://vipuser3.eweishop.com/data/attachment/3999/image/2021/12/5c14b5a030735086192561d81ff2d9c8.png",
          name: "5c14b5a030735086192561d81ff2d9c8.png",
          text: "702*300",
          is_hover: !1,
          is_select: !1,
        },
        {
          url: "https://vipuser3.eweishop.com/data/attachment/7999/image/2021/11/b4a8a68971c304924ad37dd7b2f61e3b.jpeg",
          name: "5c14b5a030735086192561d81ff2d9c8.png",
          text: "702*300",
          is_hover: !1,
          is_select: !1,
        },
        {
          url: "https://vipuser3.eweishop.com/data/attachment/3999/image/2021/11/d55b0e5a696b2f9d227bc2a111c3bac0.png",
          name: "5c14b5a030735086192561d81ff2d9c8.png",
          text: "702*300",
          is_hover: !1,
          is_select: !1,
        },
        {
          url: "https://vipuser3.eweishop.com/data/attachment/3999/image/2021/11/9e0127a90f28b53290cf9564b1030782.png",
          name: "5c14b5a030735086192561d81ff2d9c8.png",
          text: "702*300",
          is_hover: !1,
          is_select: !1,
        },
        {
          url: "https://vipuser3.eweishop.com/data/attachment/3999/image/2021/11/2a7b5b42f11c9b9227311bdbdd995473.png",
          name: "5c14b5a030735086192561d81ff2d9c8.png",
          text: "702*300",
          is_hover: !1,
          is_select: !1,
        },
        {
          url: "https://vipuser3.eweishop.com/data/attachment/7999/image/2021/11/b4a8a68971c304924ad37dd7b2f61e3b.jpeg",
          name: "5c14b5a030735086192561d81ff2d9c8.png",
          text: "702*300",
          is_hover: !1,
          is_select: !1,
        },
        {
          url: "https://vipuser3.eweishop.com/data/attachment/3999/image/2021/11/d55b0e5a696b2f9d227bc2a111c3bac0.png",
          name: "5c14b5a030735086192561d81ff2d9c8.png",
          text: "702*300",
          is_hover: !1,
          is_select: !1,
        },
        {
          url: "https://vipuser3.eweishop.com/data/attachment/3999/image/2021/11/9e0127a90f28b53290cf9564b1030782.png",
          name: "5c14b5a030735086192561d81ff2d9c8.png",
          text: "702*300",
          is_hover: !1,
          is_select: !1,
        },
        {
          url: "https://vipuser3.eweishop.com/data/attachment/3999/image/2021/11/9e0127a90f28b53290cf9564b1030782.png",
          name: "5c14b5a030735086192561d81ff2d9c8.png",
          text: "702*300",
          is_hover: !1,
          is_select: !1,
        },
       /*  {
          url: "https://vipuser3.eweishop.com/data/attachment/3999/image/2021/11/2a7b5b42f11c9b9227311bdbdd995473.png",
          name: "5c14b5a030735086192561d81ff2d9c8.png",
          text: "702*300",
          is_hover: !1,
          is_select: !1,
        },
        {
          url: "https://vipuser3.eweishop.com/data/attachment/7999/image/2021/11/b4a8a68971c304924ad37dd7b2f61e3b.jpeg",
          name: "5c14b5a030735086192561d81ff2d9c8.png",
          text: "702*300",
          is_hover: !1,
          is_select: !1,
        },
        {
          url: "https://vipuser3.eweishop.com/data/attachment/3999/image/2021/11/d55b0e5a696b2f9d227bc2a111c3bac0.png",
          name: "5c14b5a030735086192561d81ff2d9c8.png",
          text: "702*300",
          is_hover: !1,
          is_select: !1,
        },
        {
          url: "https://vipuser3.eweishop.com/data/attachment/3999/image/2021/11/9e0127a90f28b53290cf9564b1030782.png",
          name: "5c14b5a030735086192561d81ff2d9c8.png",
          text: "702*300",
          is_hover: !1,
          is_select: !1,
        },
        {
          url: "https://vipuser3.eweishop.com/data/attachment/3999/image/2021/11/9e0127a90f28b53290cf9564b1030782.png",
          name: "5c14b5a030735086192561d81ff2d9c8.png",
          text: "702*300",
          is_hover: !1,
          is_select: !1,
        },
        {
          url: "https://vipuser3.eweishop.com/data/attachment/3999/image/2021/11/2a7b5b42f11c9b9227311bdbdd995473.png",
          name: "5c14b5a030735086192561d81ff2d9c8.png",
          text: "702*300",
          is_hover: !1,
          is_select: !1,
        }, */
      ]
    },
    selectCate(id) {
      this.cate_id = id;
    },
    /**
     * 取消弹窗
     */
    handleCancel() {
      this.$emit("cancel");
    },
    /* 切换分类 */
    choseType(id) {
      this.rubik = this.rubikList.filter((m) => {
        return m.id == id;
      })[0];
    },

    /* 添加分类确认按钮 */
    popconfirm() {
      if (this.form.cate_name) {
        console.log("点击了确定", this.form.cate_name);
      }
    },
    onChange(e) {
      console.log("页码变化", e);
    },

    /* 鼠标移入移出事件 */
    enters(index) {
      this.datas[index].is_hover = this.datas[index].is_select ? !1 : !0;
    },
    leaver(index) {
      this.datas[index].is_hover = !1;
    },

    /*选择图片*/
    selectImg(index) {
      let selectLen = this.datas.filter((m) => {
        return m.is_select == !0;
      }).length;
      if (selectLen < this.num) {
        this.datas[index].is_select = !0;
        this.datas[index].number = selectLen + 1;
        this.selectLen = selectLen + 1;
        this.enters(index);
      } else {
        this.$message.warning("已到选择上限!");
      }
    },
    /*取消选择图片 */
    clearImg(index) {
      this.datas[index].is_select = !1;
      this.selectLen = this.datas.filter((m) => {
        return m.is_select == !0;
      }).length;
      this.enters(index);
    },
    /*删除图片 */
    deleteImg(index) {
      this.$confirm({
        title: "确认删除吗？",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk: () => {
          /* return new Promise((resolve, reject) => {
            this.$http
              .api("admin/delFarm", {
                farm_id: record.farm_id,
              })
              .then((res) => {
                this.$message.success("删除成功");
                this.getLists();
              })
              .catch((res) => {
                console.log(res);
              })
              .finally(() => {
                resolve();
              });
          }); */
        },
      });
    },
    /*取消全部 */
    clearAll() {
      this.datas.map((m) => {
        if (m.is_select) {
          m.is_select = !1;
        }
      });
    },

    /*图片检测*/
    beforeUpload(file) {
      const isImg =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "image/gif";
      if (!isImg) {
        this.$message.error("只能允许上传图片");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("最大限制为2M");
        return false;
      }
      return isImg && isLt2M;
    },
    /*上传图片*/
    uploadImg(e) {
      let file = e.file;
      this.loading = true;
      this.$http
        .upload(file)
        .then((res) => {
          this.pagination.current=1
          this.getdatas()
          this.loading = false;
        })
        .catch((res) => {
          this.$message.error("上传出错了");
          this.loading = false;
        });
    },
    /**
     * 提交数据
     */
    onSubmit() {
      if(this.num==1){
        let imgUrl=this.datas.filter((m) => {
          return m.is_select == !0;
        })[0].url;
        this.$emit("input", imgUrl);
      }else{
        let imgUrl=[]
        this.datas.map((m) => {
          if(m.is_select){
            imgUrl.push(m.url)
          }
        });
        this.$emit("input", imgUrl);
      }
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="less">
.ant-menu-inline > .ant-menu-item {
  height: 36px;
  line-height: 36px;
  width: 100%;
}
.ant-menu-inline {
  border: none;
}
.ant-upload-list {
  position: absolute;
}
.ant-upload-list-picture .ant-upload-list-item {
  width: 240px;
  background: #ffffff;
}
.img-box {
  width: 110px;
  height: 140px;
  margin: 0 15px 10px 0;
  position: relative;
  border-radius: 4px;
  font-size: 12px;
  text-align: center;
  .img {
    width: 110px;
    height: 110px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    border-radius: 4px;
  }
  .delete_icon {
    position: absolute;
    top: -7px;
    right: -7px;
  }
  .img-layer {
    position: absolute;
    height: 24px;
    line-height: 24px;
    text-align: center;
    bottom: 30px;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: #fff;
  }
  .select-layer {
    position: absolute;
    line-height: 110px;
    text-align: center;
    top: 0;
    bottom: 30px;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: #fff;
    overflow: hidden;
    .number {
      width: 40px;
      height: 40px;
      background: var(--primary, #4772ff);
      color: #fff;
      text-align: left;
      padding-left: 8px;
      line-height: 24px;
      border-radius: 50%;
      z-index: 2;
      position: absolute;
      bottom: -20px;
      right: -20px;
    }
  }
}
</style>
