<template>
  <div>
    <a-layout>
      <a-layout-header
        style="
          background: #ffffff;
          overflow: hidden;
          padding: 0 10px;
          height:56px;
        "
        width="100%"
      >
        <a-menu
          v-model="openKeys"
          mode="horizontal"
          style="width: 100%; line-height: 54px"
        >
          <template v-for="(item, index) in menus">
            <a-sub-menu
              :key="`sub${index}`"
              @titleClick="titleClick"
              v-if="item.child && item.child.length"
            >
              <span slot="title" class="submenu-title-wrapper">{{
                item.name
              }}</span>
              <a-menu-item
                :key="`child-${index}-${childIndex}`"
                v-for="(childItem, childIndex) in item.child"
                style="font-size: 13px; padding-left: 24px"
                :class="{ on: getSelectKey.indexOf(childItem.key) > -1 }"
                @click="goToUrl(childIndex, index)"
              >
                {{ childItem.name }}
              </a-menu-item>
            </a-sub-menu>
            <a-menu-item
              :key="index"
              :class="{ on: getSelectKey.indexOf(item.key) > -1 }"
              @click="goToUrl(index)"
              v-else
            >
              {{ item.name }}
            </a-menu-item>
          </template>
        </a-menu>
        <!--  <a-menu
          style="width: 100%; line-height: 64px"
          mode="horizontal"
          :default-open-keys="openKeys"
          :inlineIndent="10"
        >
          <template v-for="(item, index) in menus">
            <a-sub-menu
              :key="`sub${index}`"
              @titleClick="titleClick"
              v-if="item.child && item.child.length"
            >
              <span slot="title">{{ item.name }}</span>
              <a-menu-item
                :key="`child-${index}-${childIndex}`"
                v-for="(childItem, childIndex) in item.child"
                style="font-size: 13px; padding-left: 24px"
                :class="{ on: getSelectKey.indexOf(childItem.key) > -1 }"
                @click="goToUrl(childIndex, index)"
                >{{ childItem.name }}</a-menu-item
              >
            </a-sub-menu>
            <a-menu-item
              :key="index"
              :class="{ on: getSelectKey.indexOf(item.key) > -1 }"
              @click="goToUrl(index)"
              v-else
            >
              <span>{{ item.name }}</span>
            </a-menu-item>
          </template>
        </a-menu> -->
      </a-layout-header>
      <a-layout-content
        style="height: 94vh; background: #f7f8fa; min-width: 1000px;overflow-y:auto;"
      >
        <div>
          <router-view></router-view>
        </div>
      </a-layout-content>
    </a-layout>
  </div>
</template>

<script>
export default {
  props: {
    menus: {
      type: Array,
      default: [],
    },
    type: String,
    order_num: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      current: 0,
      openKeys: [],
      menuTitle: "",
    };
  },
  created() {
    this.menus.map((item, index) => {
      if (item.child && item.child.length) {
        item.child.map((childItem) => {
          if (this.getSelectKey.indexOf(childItem.key) > -1) {
            this.openKeys = [`sub${index}`];
          }
        });
      }
    });
  },
  methods: {
    titleClick(e) {
      this.openKeys.map((m) => {
        if (m != e.key) {
          this.openKeys.push(e.key);
        }
      });
    },
    goToUrl(index, fIndex = "first") {
      if (fIndex == "first") {
        this.refreshPage(this.menus[index].link);
      } else {
        this.refreshPage(this.menus[fIndex].child[index].link);
      }
    },
  },
};
</script>

<style>
.ant-menu {
  color: #000000;
  cursor: pointer;
  font-size: 14px;
}
.ant-menu-horizontal {
  border: none;
}
.ant-menu-horizontal > .ant-menu-item.on,.ant-menu-horizontal > .ant-menu-item.on:hover{
  border-bottom-color: #567af0;
  color: #567af0;
}
      /* .ant-menu-inline > .ant-menu-item,
      .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
        height: 42px;
        line-height: 42px;
      }
      .ant-menu-inline .ant-menu-item,
      .ant-menu-inline .ant-menu-submenu-title {
        width: 104px;
        margin-top: 0;
        margin-bottom: 0;
        padding-right: 0;
        border-radius: 8px;
      }
      .ant-menu-inline .ant-menu-item::after {
        border-right: none;
      }
      .ant-menu-inline .ant-menu-item:not(:last-child) {
        margin-bottom: 0;
      }
      .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
        right: 16px;
        width: 5px;
      }
      .ant-menu:not(.ant-menu-horizontal) .on {
        background: rgba(86, 122, 240, 0.06);
        color: #567af0;
      }
      .ant-menu-inline .ant-menu-submenu-title {
        padding-right: 10px;
      } */
</style>
