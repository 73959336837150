<template>
  <div>
    <div class="menu-title">
      <span class="back" @click="$router.go(-1)" v-show="isBack">
				<span>返回</span>
			</span>
      <span class="title">{{titleName}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    titleName: {
      type: String,
      default: "",
    },
		isBack:{
			type: Boolean,
      default: !1,
		}
  },
  methods: {
    
  },
};
</script>

<style>
.menu-title {
  height: 50px;
  line-height: 50px;
  width: 100%;
  background-color: #fff;
  padding: 0 20px;
  flex: 0 0 auto;
}
.menu-title .title {
  font-size: 14px;
  color: #333333;
}
.menu-title .back {
  color:#ccc;
  font-size:14px;
  padding-right:15px;
  margin-right:12px;
  border-right:1px solid #ededed;
  cursor: pointer;
}
</style>
