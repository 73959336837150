<template>
  <div>
    <a-modal
      :title="getTitle"
      :width="820"
      :visible="visible"
      @cancel="handleCancel"
      :bodyStyle="{ padding: '0px', height: '600px' }"
    >
      <a-layout>
        <a-layout-header
          style="
            background: #fff;
            height: 56px;
            line-height: 56px;
            text-align: center;
            padding: 0 10px;
          "
        >
          <a-menu
            class="classes-menu"
            :defaultSelectedKeys="[selectCurrent]"
            @click="selectMenu"
            mode="horizontal"
          >
            <a-menu-item key="local">本地视频</a-menu-item>
            <a-menu-item key="network">网络链接</a-menu-item>
          </a-menu>
        </a-layout-header>
        <div v-if="selectCurrent == 'local'">
          <localvideo @getUrl="getUrl"></localvideo>
        </div>
        <div v-if="selectCurrent == 'network'">
          <networkvideo @getUrl="getUrl"></networkvideo>
        </div>
      </a-layout>

      <template slot="footer">
        <a-button key="back" @click="handleCancel"> 取消 </a-button>
        <a-button
          key="submit"
          type="primary"
          @click="onSubmit"
          :loading="confirmLoading"
        >
          保存
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import localvideo from "./video/localvideo.vue";
import networkvideo from "./video/networkvideo.vue";
export default {
  components: {
    localvideo,
    networkvideo,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    value: {
      type:String,
      default: "",
    },
  },
  data() {
    return {
      confirmLoading: false,
      selectCurrent: "local",
      video_link:"",
    };
  },
  computed: {
    getTitle() {
      return "视频选择器";
    },
  },
  created() {},
  methods: {
    selectMenu(e) {
      this.selectCurrent = e.key;
    },
    /**
     * 取消弹窗
     */
    handleCancel() {
      this.$emit("cancel");
    },
    /**
     * 提交数据
     */
    onSubmit() {
      this.$emit("input", this.video_link);
      this.$emit("cancel");
    },
    getUrl(url){
      this.video_link=url
    }
  },
};
</script>

<style lang="less">
</style>
