<template>
  <div>
    <a-layout style="background: #fff">
      <a-layout-content style="padding: 16px">
        <div class="flex alcenter space">
          <a-input placeholder="请输入网络视频链接" class="flex-sub" v-model="video_url"/>
          <div class="flex alcenter ml20">
            <a-button type="primary" @click="preview"> 预览视频 </a-button>
          </div>
        </div>
        <div class="ft12 cl-main mt5">确保视频链接在浏览器打开直接能访问即可！</div>
        <div class="mt10" style="height:440px;">
          <video :src="video_url" controls="controls" style="width:100%;height:100%;" v-if="open_video">您的浏览器不支持视频</video>
          <img style="width:100%" :src="require(`../../../assets/image/video/videoPreview.png`)" v-else>
        </div>
      </a-layout-content>
    </a-layout>
  </div>
</template>

<script>
export default {
  data() {
    return {
      video_url:"",
      open_video:!1
    };
  },
  watch:{
    video_url(newValue){
      this.$emit('getUrl', newValue)
    }
  },
  methods: {
    preview(){
      this.open_video=!0
    }
  },
};
</script>

<style lang="less">

</style>
