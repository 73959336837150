<template>
  <div>
    <a-layout style="background: #fff">
      <a-layout-sider
        style="background: none; border-right: 1px solid #efefef; height: 540px"
        width="140px"
      >
        <a-menu
          style="width: 100%; height: 90%; padding: 16px; overflow: auto"
          mode="inline"
          :inlineIndent="10"
        >
          <template v-for="(item, index) in menus">
            <a-menu-item
              :key="index"
              :class="{ on: item.id == cate_id }"
              @click="selectCate(item.id)"
            >
              <span class="ft12">{{ item.name }}</span>
            </a-menu-item>
          </template>
        </a-menu>
        <div class="flex center pd10">
          <a-popconfirm
            placement="top"
            ok-text="确定"
            cancel-text="取消"
            @confirm="popconfirm"
          >
            <template slot="title">
              <p>分类名称</p>
              <p>
                <a-input
                  placeholder="分类名称"
                  v-model="form.cate_name"
                  :suffix="`${form.cate_name.length}/5`"
                  :maxLength="5"
                  style="width: 130px"
                />
              </p>
            </template>
            <a-button>新建分类</a-button>
          </a-popconfirm>
        </div>
      </a-layout-sider>
      <a-layout-content style="padding: 16px">
        <div class="flex alcenter space">
          <a-input-search
            placeholder="搜索视频名称"
            style="width: 200px"
            @search="getdatas"
            v-model="form.video_name"
          />
          <div class="flex alcenter">
            <div class="ft12 cl-info mr10">建议大小不要超过2M</div>
            <a-upload
              list-type="picture"
              :multiple="!0"
              :showUploadList="false"
              :customRequest="upload"
              :beforeUpload="beforeUpload"
            >
              <a-button type="primary"> 上传视频 </a-button>
            </a-upload>
          </div>
        </div>
        <div class="mt30" style="height: 390px">
          <a-row justify="center" align="middle">
            <a-col
              class="video-box"
              v-for="(item, index) in datas"
              :key="index"
              @mouseenter="enters(index)"
              @mouseleave="leaver(index)"
              @click="seleted(index)"
              :class="{ active: item.is_select }"
            >
              <video :src="item.url" class="video"></video>
              <div class="video-des">
                <div class="text-over text-over1">{{ item.name }}</div>
                <div class="text-over text-over1">{{ item.text }}</div>
              </div>
              <a-icon
                class="delete_icon ft16 bg-w"
                type="close-circle"
                theme="filled"
                @click.stop="deleteVideo(index)"
                v-show="item.is_hover"
              />
            </a-col>
          </a-row>
        </div>
        <div class="pt20 flex alcenter space">
          <div></div>
          <a-pagination
            show-quick-jumper
            :defaultPageSize="pagination.pageSize"
            v-model="pagination.current"
            :total="pagination.total"
            @change="onChange"
          />
        </div>
      </a-layout-content>
    </a-layout>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      menus: [
        { id: 0, name: "全部" },
        { id: 1, name: "分类一" },
        { id: 2, name: "分类二" },
        { id: 3, name: "分类三" },
      ],
      cate_id: 0,
      form: {
        cate_name: "",
        video_name: "",
      },
      pagination: {
        current: 1,
        pageSize: 12, //每页中显示10条数据
        total: 10,
      },
      datas: [],
    };
  },
  created() {
    console.log(this.value);
    this.getdatas();
  },
  methods: {
    getdatas() {
      this.datas = [
        {
          url: "https://vipuser3.eweishop.com/data/attachment/3999/video/2021/07/d94842d957b589a34e7f6cab453bd9b7.mp4",
          name: "5c14b5a030735086192561d81ff2d9c8.png",
          text: "1.08M",
          is_hover: !1,
          is_select: !1,
        },
        {
          url: "https://vipuser3.eweishop.com/data/attachment/3999/video/2021/07/d94842d957b589a34e7f6cab453bd9b7.mp4",
          name: "5c14b5a030735086192561d81ff2d9c8.png",
          text: "1.08M",
          is_hover: !1,
          is_select: !1,
        },
        {
          url: "https://vipuser3.eweishop.com/data/attachment/3999/video/2021/07/d94842d957b589a34e7f6cab453bd9b7.mp4",
          name: "5c14b5a030735086192561d81ff2d9c8.png",
          text: "1.08M",
          is_hover: !1,
          is_select: !1,
        },
        {
          url: "https://vipuser3.eweishop.com/data/attachment/3999/video/2021/07/d94842d957b589a34e7f6cab453bd9b7.mp4",
          name: "5c14b5a030735086192561d81ff2d9c8.png",
          text: "1.08M",
          is_hover: !1,
          is_select: !1,
        },
        {
          url: "https://vipuser3.eweishop.com/data/attachment/3999/video/2021/07/d94842d957b589a34e7f6cab453bd9b7.mp4",
          name: "5c14b5a030735086192561d81ff2d9c8.png",
          text: "1.08M",
          is_hover: !1,
          is_select: !1,
        },
        {
          url: "https://vipuser3.eweishop.com/data/attachment/3999/video/2021/07/d94842d957b589a34e7f6cab453bd9b7.mp4",
          name: "5c14b5a030735086192561d81ff2d9c8.png",
          text: "1.08M",
          is_hover: !1,
          is_select: !1,
        },
        {
          url: "https://vipuser3.eweishop.com/data/attachment/3999/video/2021/07/d94842d957b589a34e7f6cab453bd9b7.mp4",
          name: "5c14b5a030735086192561d81ff2d9c8.png",
          text: "1.08M",
          is_hover: !1,
          is_select: !1,
        },
        {
          url: "https://vipuser3.eweishop.com/data/attachment/3999/video/2021/07/d94842d957b589a34e7f6cab453bd9b7.mp4",
          name: "5c14b5a030735086192561d81ff2d9c8.png",
          text: "1.08M",
          is_hover: !1,
          is_select: !1,
        },
        {
          url: "https://vipuser3.eweishop.com/data/attachment/3999/video/2021/07/d94842d957b589a34e7f6cab453bd9b7.mp4",
          name: "5c14b5a030735086192561d81ff2d9c8.png",
          text: "1.08M",
          is_hover: !1,
          is_select: !1,
        },
      ];
    },
    /* 切换分类 */
    selectCate(id) {
      this.cate_id = id;
    },
    /* 添加分类确认按钮 */
    popconfirm() {
      if (this.form.cate_name) {
        console.log("点击了确定", this.form.cate_name);
      }
    },
    onChange(e) {
      console.log("页码变化", e);
    },

    /* 鼠标移入移出事件 */
    enters(index) {
      this.datas[index].is_hover = this.datas[index].is_select ? !1 : !0;
    },
    leaver(index) {
      this.datas[index].is_hover = !1;
    },

    /*选择*/
    seleted(index) {
      this.datas.map((m, dix) => {
        m.is_select = dix == index ? !m.is_select : !1;
      });
      this.$emit("getUrl", this.datas[index].url);
      this.enters(index);
    },
    /*删除 */
    deleteVideo(index) {
      this.$confirm({
        title: "确认删除吗？",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk: () => {
          /* return new Promise((resolve, reject) => {
            this.$http
              .api("admin/delFarm", {
                farm_id: record.farm_id,
              })
              .then((res) => {
                this.$message.success("删除成功");
                this.getLists();
              })
              .catch((res) => {
                console.log(res);
              })
              .finally(() => {
                resolve();
              });
          }); */
        },
      });
    },

    /*检测*/
    beforeUpload(file) {
      /* const isImg =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "image/gif";
      if (!isImg) {
        this.$message.error("只能允许上传图片");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("最大限制为2M");
        return false;
      }
      return isImg && isLt2M; */
      return true;
    },
    /*上传*/
    upload(e) {
      let file = e.file;
      this.loading = true;
      this.$http
        .upload_video(file)
        .then((res) => {
          this.pagination.current = 1;
          this.getdatas();
          this.loading = false;
        })
        .catch((res) => {
          this.$message.error("上传出错了");
          this.loading = false;
        });
    }
  },
};
</script>

<style lang="less">
.ant-menu-inline > .ant-menu-item {
  height: 36px;
  line-height: 36px;
  width: 100%;
}
.ant-menu-inline {
  border: none;
}
.ant-upload-list {
  position: absolute;
}
.ant-upload-list-picture .ant-upload-list-item {
  width: 240px;
  background: #ffffff;
}
.video-box {
  float: left;
  width: 200px;
  height: 75px;
  border-radius: 4px;
  border: 1px solid #ededed;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 8px 12px;
  display: flex;
  position: relative;
  .video {
    width: 60px;
    height: 60px;
    border-radius: 4px;
    display: inline-block;
  }
  .video-des {
    flex: 1;
    padding-left: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 5px 0 5px 14px;
    color: #333;
  }
  .delete_icon {
    position: absolute;
    top: -7px;
    right: -7px;
  }
}
.video-box.active {
  border-color: #4772ff;
}
</style>
