const  router = [
	{
		path: '/service',
		component: () => import('../../../pages/service/Index.vue'),
		meta: {title: '服务'},
		name: 'serviceIndex'
	},
	{
		path: '/service/detail',
		component: () => import('../../../pages/service/Detail.vue'),
		meta: {title: '服务详情'},
		name: 'serviceIndex-detail'
	},
	{
		path: '/mendian',
		component: () => import('../../../pages/store/Index.vue'),
		meta: {title: '店铺列表'},
		name: 'storeIndex'
	},
];

export default router